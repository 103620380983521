<template>
  <!-- begin::Addresses edit -->
  <div>
    <div class="manager-title">
      <div>
        {{
          AddressID > 0
            ? $t("FREQUENT_ADDRESSES.TITLE_EDIT")
            : $t("FREQUENT_ADDRESSES.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("FREQUENT_ADDRESSES.SUBTITLE") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.NICK") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Nick"
              :placeholder="$t('FREQUENT_ADDRESSES.NICK')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.TRADE_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.TradeName"
              :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="col-lg-8 d-flex align-items-center">
            <inline-svg src="/media/icons/help-alt-5-primary.svg" />
            <span class="pl-3">
              {{ $t("FREQUENT_ADDRESSES.TRADE_NAME_INFO") }}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.FULL_ADDRESS_2") }}
            </label>
            <VueGoogleAutocomplete
              ref="autocompleteAddress"
              id="autocompleteAddress"
              country="es"
              :fields="['address_components', 'geometry', 'id', 'name']"
              v-model="Fields.Street"
              :rules="[rules.required]"
              required
              classname="form-control"
              placeholder=""
              @placechanged="onAutocompletePlaceChanged"
              @inputChange="onAutocompleteInputChange"
            />
            <div
              v-if="!addressValid"
              class="v-text-field__details pt-2 pl-5 pb-0 m-0"
            >
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-2">
            <label>
              {{ $t("FREQUENT_ADDRESSES.POSTAL_CODE_2") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.PostalCode"
              :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
              required
              :rules="[rules.required]"
              @keyup="onPostalCodeChanged"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("FREQUENT_ADDRESSES.CITY") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.City"
              :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.ProvinceID"
              required
            >
              <option
                v-for="p in ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.CONTACT_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.ContactName"
              :placeholder="$t('FREQUENT_ADDRESSES.CONTACT_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.CONTACT_EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.ContactEmail"
              :placeholder="$t('FREQUENT_ADDRESSES.CONTACT_EMAIL')"
              required
              :rules="[rules.required, rules.email]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("FREQUENT_ADDRESSES.CONTACT_PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.ContactPhone"
              :placeholder="$t('FREQUENT_ADDRESSES.CONTACT_PHONE')"
              required
              :rules="[rules.required]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/addresses')"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid || !addressValid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Addresses edit -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";

import AddressService from "@/core/services/api/v2/address.service";
import AddressTypeService from "@/core/services/api/v2/addresstype.service";
import ProvinceService from "@/core/services/api/v2/province.service";

export default {
  name: "AddressesEdit",
  components: {
    VueGoogleAutocomplete
  },
  computed: {
    addressValid() {
      return this.Fields.Street.length > 0;
    }
  },
  data() {
    return {
      AddressID: 0,
      ProvinceOptions: [],
      AddressTypeOptions: [],
      Valid: true,
      Fields: {
        Nick: "",
        AddressTypeID: 0,
        TradeName: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0,
        ContactName: "",
        ContactEmail: "",
        ContactPhone: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },
  created() {
    this.loadProvinceOptions();
    this.loadAddressTypeOptions();
  },
  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.AddressID = parseInt(this.$route.params.id);
      this.loadAddressData();
    } else {
      this.AddressID = -1;
    }
  },
  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        this.ProvinceOptions = response;
      });
    },

    loadAddressTypeOptions() {
      this.AddressTypeOptions = [];
      AddressTypeService.listAll().then(response => {
        this.AddressTypeOptions = response;
      });
    },

    loadAddressData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      AddressService.getProfile(this.AddressID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/addresses");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onAutocompletePlaceChanged(addressData) {
      this.Fields.Street =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");

      this.Fields.PostalCode = addressData.postal_code;
      this.Fields.City = addressData.locality;
      this.onPostalCodeChanged();

      // Clean extra values from Street box
      this.$refs.autocompleteAddress.$refs.autocomplete.value = this.Fields.Street;
    },

    onAutocompleteInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.Fields.Street = "";
      }
    },

    onPostalCodeChanged() {
      // Convert PostalCode to an item in our ProvinceID selector
      // N.B: Province is CRITICAL because it sets the fare,
      // but Google doesn't give it,
      // that's why we have to guess it via PostalCode...
      // and why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this.Fields.PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.Fields.ProvinceID = newProvinceID;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.AddressID > 0) {
          // Update Address
          AddressService.updateProfile(this.AddressID, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 409) {
                  whyError = this.$i18n.t("FREQUENT_ADDRESSES.ERROR_409");
                } else if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Address: we get back its ID
          AddressService.createProfile(this.Fields).then(response => {
            if (response.AddressID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.AddressID = response.AddressID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/addresses/edit/" + this.AddressID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("FREQUENT_ADDRESSES.ERROR_409");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
